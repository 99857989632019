import React from 'react';
import ReactApexChart from 'react-apexcharts';

import { functions } from "../../../utils/functions";
import { vitalSignsService } from "../../../services/vital_signs.service";

class VitalSigns extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            vitalSign: '',
            seriesTA: [],
            seriesFrxl: [],
            seriesTemp: [],
            seriesSat: [],
            options: {
                chart: {
                    height: 200,
                    type: "line",
                    toolbar: {
                        show: false
                    }
                },
                dataLabels: {
                    enabled: false
                },
                xaxis: {
                    categories: []
                },
            }
        }
    }

    componentDidMount() {
        this.getVitalsSigns()
    }

    getVitalsSigns = () => {
        vitalSignsService.getVitalSignsResident(this.props.resident.id).onSnapshot({
          next: snapshot => {
            let vitalSigns = [];
            snapshot.forEach(vitalSign => {
              let vitals = vitalSign.data();
              vitals.id = vitalSign.id
              vitals.t_a = functions.formatTA(vitals.t_a);
              vitalSigns.push(vitals);
            });
    
            if(vitalSigns.length > 0){

                this.setState({
                    vitalSign: vitalSigns[0]
                })
                
                let seriesTA = [
                    this.itemSeries("Sistole", '#FF8000'),
                    this.itemSeries("Distole", '#0431B4'),
                    this.itemSeriesLimit("Sis. nor.", 120, vitalSigns.length, '#3ADF00'),
                    this.itemSeriesLimit("Dis. nor.", 80, vitalSigns.length, '#3ADF00'),
                ]
                let seriesFrxl = [
                    this.itemSeries("F. Respiratoria", '#2E37A4'),
                    this.itemSeriesLimit("FR. max.", 30, vitalSigns.length),
                    this.itemSeriesLimit("FR. min.", 10, vitalSigns.length),
                ]
                let seriesTemp = [
                    this.itemSeries("Temperatura", '#2E37A4'),
                    this.itemSeriesLimit("Temp max.", 37.2, vitalSigns.length),
                    this.itemSeriesLimit("Temp. min.", 36.1, vitalSigns.length),
                ]
                let seriesSat = [
                    this.itemSeries("Saturación", '#2E37A4'),
                    this.itemSeriesLimit("Sat. min. ", 90, vitalSigns.length),
                ]
                vitalSigns.reverse().forEach(item => {
                //   let dateSigns = functions.timestampToString(item.create_date, 'mm-dd');
                //   residents[index].labels.push(dateSigns);
        
                  let arrayValueTA = item.t_a.split('/');
                  seriesTA[0].data.push(arrayValueTA[0]);
                  seriesTA[1].data.push(arrayValueTA[1]);
                  seriesFrxl[0].data.push(item.fr_xl);
                  seriesTemp[0].data.push(item.temp);
                  seriesSat[0].data.push(item.sat);
                })
    
                this.setState({seriesTA, seriesFrxl, seriesTemp, seriesSat})
            }
          }
        })
    }

    itemSeries(name, color) {
        return {
            name: name,
            color: color,
            data: []
        }
    }

    itemSeriesLimit(name, limit, length, color) {
        let valueColor = '#FF0000';
        if(color){
            valueColor = color;
        }

        let valueData = [];
        for (let index = 0; index < length; index++) {
            valueData.push(limit);
        }
        return {
            name: name,
            color: valueColor,
            data: valueData
        }
    }

    render() {
        const { resident } = this.props
        return (
            <div >
                <div className="title-vital-signs">
                    A continuación, condición de salud de <strong>{resident.name}</strong>
                </div>
                <div className="row">
                    <div className="col-12 col-md-6 col-xl-3 d-flex">
                        <div className="card report-blk">
                            <div className="card-body">
                                <div className="report-head">
                                    <h4>
                                        Tensión Arterial
                                    </h4>
                                </div>
                                <ReactApexChart 
                                    options={{
                                        chart: this.state.options.chart,
                                        dataLabels: this.state.options.dataLabels,
                                        xaxis: this.state.options.xaxis,
                                        stroke: {
                                            curve: "smooth",
                                            width: [1, 1, 2.5, 2.5]
                                        }
                                    }} 
                                    series={this.state.seriesTA}
                                    type="line" 
                                    height={200} 
                                />
                                <div className="dash-content">
                                    <h5>
                                        {this.state.vitalSign.t_a} <span>T/A</span>
                                    </h5>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-6 col-xl-3 d-flex">
                        <div className="card report-blk">
                            <div className="card-body">
                                <div className="report-head">
                                    <h4>
                                        F. Respiratoria
                                    </h4>
                                </div>
                                <ReactApexChart 
                                    options={{
                                        chart: this.state.options.chart,
                                        dataLabels: this.state.options.dataLabels,
                                        xaxis: this.state.options.xaxis,
                                        stroke: {
                                            curve: "smooth",
                                            width: [1, 2.5, 2.5]
                                        }
                                    }} 
                                    series={this.state.seriesFrxl} 
                                    type="line" 
                                    height={200} 
                                />
                                <div className="dash-content">
                                    <h5>
                                        {this.state.vitalSign.fr_xl} <span>FRx1</span>
                                    </h5>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-6 col-xl-3 d-flex">
                        <div className="card report-blk">
                            <div className="card-body">
                                <div className="report-head">
                                    <h4>
                                        Temperatura
                                    </h4>
                                </div>
                                <ReactApexChart 
                                    options={{
                                        chart: this.state.options.chart,
                                        dataLabels: this.state.options.dataLabels,
                                        xaxis: this.state.options.xaxis,
                                        stroke: {
                                            curve: "smooth",
                                            width: [1, 2.5, 2.5]
                                        }
                                    }} 
                                    series={this.state.seriesTemp} 
                                    type="line" 
                                    height={200} 
                                />
                                <div className="dash-content">
                                    <h5>
                                        {this.state.vitalSign.temp} <span>c</span>
                                    </h5>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-6 col-xl-3 d-flex">
                        <div className="card report-blk">
                            <div className="card-body">
                                <div className="report-head">
                                    <h4>
                                        Saturación
                                    </h4>
                                </div>
                                <ReactApexChart 
                                    options={{
                                        chart: this.state.options.chart,
                                        dataLabels: this.state.options.dataLabels,
                                        xaxis: this.state.options.xaxis,
                                        stroke: {
                                            curve: "smooth",
                                            width: [1, 2.5]
                                        }
                                    }} 
                                    series={this.state.seriesSat} 
                                    type="line" 
                                    height={200} 
                                />
                                <div className="dash-content">
                                    <h5>
                                        {this.state.vitalSign.sat} <span>Sat.</span>
                                    </h5>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        );
    }
}

export default VitalSigns;