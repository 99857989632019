/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useContext } from "react";
import { DatePicker, Select } from "antd";
import CurrencyInput from 'react-currency-input-field';
import algoliasearch from "algoliasearch/lite";

import { functions } from '../../utils/functions';
import { alertsApp } from '../../utils/alertsApp';
import algoliaConfig from "../../utils/Algolia";

import { userService } from "../../services/user.service";

import dayjs from 'dayjs';

const client = algoliasearch(algoliaConfig.aplicationID, algoliaConfig.searchApiKey);
const indexSearch = client.initIndex(algoliaConfig.indexCountries);

const PersonalInformation = ({ origin, context }) => {
    const { user, setUser } = useContext(context)
    const { resident, setResident } = useContext(context)

    const [countries, setCountries] = useState([])
    const [selectedGender, setSelectedGender] = useState('');
    const [selectedSecurity, setSelectedSecurity] = useState('');
    const [isEdit, setIsEdit] = useState(false);
    const [isUserEdit, setIsUserEdit] = useState(false);

    const onChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        let arrayName = name.split('.');
        let section = arrayName[0];
        let field = arrayName[1];
        if (section === 'user') {
            changeFieldsUser(field, value);
        }
        if (section === 'resident') {
            changeFieldsResident(field, value);
        }
    };

    const changeFieldsUser = (name, value) => {
        let data = { ...user };
        if (name === 'document' || name === 'phone' || name === 'phone1' || name === 'phone2') {
            data[name] = functions.validNumber(value);
        } else {
            if (name === 'email') {
                data[name] = value.toLowerCase();
            } else {
                data[name] = value;
            }
        }
        setUser(data);
    }

    const changeFieldsResident = (name, value) => {
        let data = { ...resident };
        if (name === 'document' || name === 'room_number' || name === 'bed_number'
            || name === 'monthly_pension' || name === 'payday') {
            data[name] = functions.validNumber(value);
        } else {
            data[name] = value;
        }
        setResident(data);
    }

    const onChangeDate = (date, dateString) => {
        let data = { ...resident };
        data.birthday = dateString;
        setResident(data);
    };

    const onChangeSelect = (value, name) => {
        let arrayName = name.split('.');
        let section = arrayName[0];
        let field = arrayName[1];
        if (section === 'resident') {
            changeFieldsResident(field, value);
        }
    }

    const onChangeInputPrice = (result) => {
        let data = { ...resident };
        data.monthly_pension = result;
        setResident(data);
    }

    const onBlur = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        let arrayName = name.split('.');
        let section = arrayName[0];
        let field = arrayName[1];
        if (section === 'user') {
            blurUser(field, value);
        }
        if (section === 'resident') {
            blurResident(field, value);
        }
    }

    const blurUser = (name, value) => {
        let data = { ...user };
        if (value) {
            if (name === 'email' || name === 'mail') {
                let validEmail = functions.validEmail(value);
                if (!validEmail) {
                    alertsApp.errorAlert('Error: La dirección de correo es incorrecta');
                    data[name] = '';
                    setUser(data);
                } else {
                    getUserByEmail(value, data);
                }
            }

            if (name === 'phone' || name === 'phone1' || name === 'phone2') {
                let validPhone = functions.validPhone(value);
                if (!validPhone) {
                    alertsApp.errorAlert('Número telefónico incorrecto');
                    data[name] = '';
                    setUser(data);
                }
            }

            if (name == 'document') {
                getUserByDocument(value, data);
            }
        }
    }

    const blurResident = (name, value) => {
        let data = { ...resident };
        if (value) {
            if (name === 'payday') {
                if(value > 31){
                    alertsApp.errorAlert('El dia de pago no puede ser mayor a 31');
                    data[name] = '';
                    setResident(data);
                }
            }
        }
    }

    const handleSearch = (newValue) => {
        getCountries(newValue);
    };

    const getUserByDocument = (document, data) => {
        setIsUserEdit(false);
        data.name = '';
        data.lastname = '';
        data.phone = '';
        data.phone1 = '';
        data.phone2 = '';
        data.email = '';
        userService.getUserByDocument(document).then(snapshot => {
            if (snapshot.docs.length > 0) {
                let userRegister = '';
                snapshot.forEach(item => {
                    userRegister = item.data();
                })
                if (userRegister.ident_rol !== 3) {
                    alertsApp.errorAlert('El número de documento ya se encuentra registrado con otro tipo usuario');
                    data.document = '';
                } else {
                    data.name = userRegister.name;
                    data.lastname = userRegister.lastname;
                    data.phone = userRegister.phone;
                    data.phone1 = userRegister.phone1;
                    data.phone2 = userRegister.phone2;
                    data.email = userRegister.email;
                    setIsUserEdit(true);
                }
            }
            setUser(data);
        })
    }

    const getUserByEmail = (email, data) => {
        userService.getUserByEmail(email).then(snapshot => {
            if (snapshot.docs.length > 0) {
                alertsApp.errorAlert('El email ya se encuentra registrado con otro usuario');
                data.email = '';
                setUser(data);
            }
            // if (snapshot.docs.length > 0) {
            //     let userRegister = '';
            //     snapshot.forEach(item => {
            //         userRegister = item.data();
            //     })
            //     if (userRegister.ident_rol !== 3) {
            //         alertsApp.errorAlert('El email ya se encuentra registrado con otro tipo usuario');
            //         data.document = '';
            //     } else {
            //         data.name = userRegister.name;
            //         data.lastname = userRegister.lastname;
            //         data.phone = userRegister.phone;
            //         data.phone1 = userRegister.phone1;
            //         data.phone2 = userRegister.phone2;
            //         data.email = userRegister.email;
            //         setIsUserEdit(true);
            //     }
            //     setUser(data);
            // }
        })
    }

    const getCountries = (search) => {
        client.clearCache();
        indexSearch.search(search)
            .then(({ hits }) => {
                let countries = [];
                hits.forEach(item => {
                    item.text = item.name;
                    countries.push(item);
                })
                setCountries(countries);
            })
            .catch(err => {
                console.error(err);
            });

    }

    useEffect(() => {
        if (origin === 'add') {
            let date = new Date();
            let dateStr = functions.generateDateStr(date, '-', false);
            let data = { ...resident };
            data.birthday = dateStr;
            setIsEdit(false);
            setResident(data);
        } else {
            setIsEdit(true);
            setSelectedGender(resident.gender);
        }
        getCountries();
    }, [])

    return (
        <div>
            <div className="row" style={{ marginTop: 15 }}>
                <div className="col-md-3">
                    <div className="form-group local-forms">
                        <label className="focus-label">N° documento <span className="login-danger">*</span></label>
                        <input
                            type="text"
                            name="resident.document"
                            className="form-control floating"
                            maxLength={10}
                            value={resident.document}
                            onChange={onChange}
                        />
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="form-group local-forms">
                        <label className="focus-label">Primer nombre <span className="login-danger">*</span></label>
                        <input
                            type="text"
                            name="resident.name"
                            className="form-control floating"
                            value={resident.name}
                            onChange={onChange}
                        />
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="form-group local-forms">
                        <label className="focus-label">Primer apellido <span className="login-danger">*</span></label>
                        <input
                            type="text"
                            name="resident.lastname"
                            className="form-control floating"
                            value={resident.lastname}
                            onChange={onChange}
                        />
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="form-group local-forms">
                        <label className="focus-label">Tipo de sangre <span className="login-danger">*</span></label>
                        <input
                            type="text"
                            name="resident.blood_type"
                            className="form-control floating"
                            maxLength={10}
                            value={resident.blood_type}
                            onChange={onChange}
                        />
                    </div>
                </div>
                <div className="col-md-3" style={{ fontSize: '0.8em' }}>
                    <div className="form-group local-forms">
                        <label className="focus-label">Genero</label>
                        <Select
                            name="resident.gender"
                            value={resident.gender}
                            onChange={value => onChangeSelect(value, "resident.gender")}
                            className="form-control floating"
                        >
                            <Select.Option value="Hombre">Hombre</Select.Option>
                            <Select.Option value="Mujer">Mujer</Select.Option>
                        </Select>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="form-group local-forms">
                        <label className="focus-label">Tipo de seguro <span className="login-danger">*</span></label>
                        <Select
                            name="resident.security_type"
                            value={resident.security_type}
                            onChange={value => onChangeSelect(value, "resident.security_type")}
                            className="form-control floating"
                        >
                            <Select.Option value="Ninguno">Ninguno</Select.Option>
                            <Select.Option value="Privado">Privado</Select.Option>
                            <Select.Option value="Social">Social</Select.Option>
                        </Select>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="form-group local-forms">
                        <label className="focus-label">País de nacimiento <span className="login-danger">*</span></label>
                        <Select
                            showSearch
                            value={resident.country}
                            placeholder="Seleccionar país"
                            defaultActiveFirstOption={false}
                            suffixIcon={null}
                            filterOption={false}
                            onSearch={handleSearch}
                            onChange={value => onChangeSelect(value, "resident.country")}
                            notFoundContent={null}
                            className="form-control floating"
                            options={(countries || []).map((d) => ({
                                value: d.text,
                                label: d.text,
                            }))}
                        />
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="form-group local-forms">
                        <label className="focus-label">Fecha de nacimiento <span className="login-danger">*</span></label>
                        <DatePicker
                            value={dayjs(resident.birthday, 'YYYY-MM-DD')}
                            className="form-control datetimepicker"
                            onChange={onChangeDate}
                            suffixIcon={null}
                        // placeholder='24/11/2022'
                        />
                    </div>
                </div>
            </div>
            <hr />
            <div className="row">
                <div className="col-md-12 title-resident">
                    Datos representante o tutor
                </div>
                <div className="col-md-3">
                    <div className="form-group local-forms">
                        <label className="focus-label">N° documento <span className="login-danger">*</span></label>
                        <input
                            type="text"
                            name="user.document"
                            className="form-control floating"
                            maxLength={10}
                            value={user.document}
                            onChange={onChange}
                            onBlur={onBlur}
                            disabled={isEdit}
                        />
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="form-group local-forms">
                        <label className="focus-label">Primer nombre <span className="login-danger">*</span></label>
                        <input
                            type="text"
                            name="user.name"
                            className="form-control floating"
                            value={user.name}
                            onChange={onChange}
                            disabled={isEdit || isUserEdit}
                        />
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="form-group local-forms">
                        <label className="focus-label">Primer apellido <span className="login-danger">*</span></label>
                        <input
                            type="text"
                            name="user.lastname"
                            className="form-control floating"
                            value={user.lastname}
                            onChange={onChange}
                            disabled={isEdit || isUserEdit}
                        />
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="form-group local-forms">
                        <label className="focus-label">N° celular <span className="login-danger">*</span></label>
                        <input
                            type="text"
                            name="user.phone"
                            className="form-control floating"
                            maxLength={10}
                            value={user.phone}
                            onChange={onChange}
                            onBlur={onBlur}
                        />
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="form-group local-forms">
                        <label className="focus-label">N° celular 1 </label>
                        <input
                            type="text"
                            name="user.phone1"
                            className="form-control floating"
                            maxLength={10}
                            value={user.phone1}
                            onChange={onChange}
                            onBlur={onBlur}
                        />
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="form-group local-forms">
                        <label className="focus-label">N° celular 2 </label>
                        <input
                            type="text"
                            name="user.phone2"
                            className="form-control floating"
                            maxLength={10}
                            value={user.phone2}
                            onChange={onChange}
                            onBlur={onBlur}
                        />
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="form-group local-forms">
                        <label className="focus-label">Email <span className="login-danger">*</span></label>
                        <input
                            type="text"
                            name="user.email"
                            className="form-control floating"
                            value={user.email}
                            onChange={onChange}
                            onBlur={onBlur}
                            disabled={isEdit || isUserEdit}
                        />
                    </div>
                </div>

            </div>
            <hr />
            <div className="row">
                <div className="col-md-12 title-resident">
                    Datos generales
                </div>
                <div className="col-md-2">
                    <div className="form-group local-forms">
                        <label className="focus-label">N° habitación <span className="login-danger">*</span></label>
                        <input
                            type="text"
                            name="resident.room_number"
                            className="form-control floating"
                            maxLength={10}
                            value={resident.room_number}
                            onChange={onChange}
                            onBlur={onBlur}
                        />
                    </div>
                </div>
                <div className="col-md-2">
                    <div className="form-group local-forms">
                        <label className="focus-label">N° cama <span className="login-danger">*</span></label>
                        <input
                            type="text"
                            name="resident.bed_number"
                            className="form-control floating"
                            maxLength={10}
                            value={resident.bed_number}
                            onChange={onChange}
                            onBlur={onBlur}
                        />
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="form-group local-forms">
                        <label className="focus-label">F. pago <span className="login-danger">*</span></label>
                        <Select
                            name="resident.method_pay"
                            value={resident.method_pay}
                            onChange={value => onChangeSelect(value, "resident.method_pay")}
                            className="form-control floating"
                        >
                            <Select.Option value="Tranferencia">Tranferencia</Select.Option>
                            <Select.Option value="Deposito">Deposito</Select.Option>
                            <Select.Option value="Efectivo">Efectivo</Select.Option>
                        </Select>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="form-group local-forms">
                        <label className="focus-label">Pensión mensual <span className="login-danger">*</span></label>
                        <CurrencyInput
                            id="monthly_pension"
                            name="resident.monthly_pension"
                            className="form-control floating"
                            value={resident.monthly_pension}
                            decimalsLimit={2}
                            prefix="$"
                            decimalSeparator="."
                            groupSeparator=","
                            onValueChange={onChangeInputPrice}
                        />
                    </div>
                </div>
                <div className="col-md-2">
                    <div className="form-group local-forms">
                        <label className="focus-label">Día de pago <span className="login-danger">*</span></label>
                        <input
                            type="text"
                            name="resident.payday"
                            className="form-control floating"
                            maxLength={10}
                            value={resident.payday}
                            onChange={onChange}
                            onBlur={onBlur}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PersonalInformation;
