/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useContext } from "react";
import { Select, Space, Tooltip, TimePicker, Table } from 'antd';
import { Link } from "react-router-dom";
import dayjs from 'dayjs';
import { imagesend } from "../imagepath";

import { functions } from '../../utils/functions';
import { alertsApp } from '../../utils/alertsApp';
import { medicinesResidentsService } from "../../services/medicines_resident.service";

const MedicinesResident = ({origin, context}) => {
    const {medicines, setMedicines} = useContext(context)
    const {resident, setResident} = useContext(context)
    const [medicines_resident, setMedicinesResident] = useState({
        id_resident: '',
        medication_name: '',
        weight: '',
        unit_measurement: '',
        administration: [],
        administration_str: '',
        number_hours: '0',
        hours: [],
        hours_str: '',
        status: '',
        create_date: '',
        update_date: '',
        user_create: '',
        user_update: ''
    });
    const [isEdit, setIsEdit] = useState(false);
    const [exitDocumentResident, setExitDocumentResident] = useState(false);
    const [selectedadministration, setSelectedadministration] = useState([]);
    const [medicineSelect, setMedicineSelect] = useState(null);
    const [countId, setCountId] = useState(0);

    const format = 'HH:mm';
    const options = [
        { label: 'Lunes', value: 'Lunes' },
        { label: 'Martes', value: 'Martes' },
        { label: 'Miércoles', value: 'Miércoles' },
        { label: 'Jueves', value: 'Jueves' },
        { label: 'Viernes', value: 'Viernes' },
        { label: 'Sábado', value: 'Sábado' },
        { label: 'Domingo', value: 'Domingo' },
        { label: 'Todos los días', value: 'Todos los días' },
        { label: 'Por razones necesarias', value: 'Por razones necesarias' },
    ];
    const sharedProps = {
        mode: 'multiple',
        style: { width: '100%' },
        options,
        placeholder: 'Select Item...',
        maxTagCount: 'responsive',
    };

    const selectProps = {
        value: selectedadministration,
        onChange: setSelectedadministration,
    };

    const column = [
        {
            title: "Nombre",
            dataIndex: "medication_name",
            key: "medication_name",
        },
        {
            title: "Gramaje",
            dataIndex: "weight",
            key: "weight",
        },
        {
            title: "U. medida",
            dataIndex: "unit_measurement",
            key: "unit_measurement",
        },
        {
            title: "Administración",
            dataIndex: "administration_str",
            key: "administration_str",
        },
        {
            title: "Horas",
            dataIndex: "hours_str",
            key: "hours_str",
        },
        {
            title: "",
            dataIndex: "FIELD8",
            render: (text, record) => (
                <>
                    <div className="text-end">
                        <div>
                            <Link className="dropdown-item" to="#" data-bs-toggle="modal"
                                data-bs-target="#delete_patient"
                                onClick={selectRow(record)}
                            >
                                <i className="fa fa-trash-alt m-r-5"></i> Eliminar
                            </Link>
                        </div>
                    </div>
                </>
            ),
        },
    ];

    const selectRow = (row) => () => {
        setMedicineSelect(row);
    }

    const onChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        let arrayName = name.split('.');
        let field = arrayName[1];
        let data = { ...medicines_resident };
        if (field === 'number_hours' || field === 'weight') {
            data[field] = functions.validNumber(value);
        } else {
            data[field] = value;
        }
        setMedicinesResident(data);
    };

    const onBlur = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        let arrayName = name.split('.');
        let field = arrayName[1];
        let data = { ...medicines_resident };
        data.hours = [];
        if (field === 'number_hours') {
            for (let index = 0; index < parseInt(data.number_hours); index++) {
                data.hours.push('00.00');
            }
        }
        setMedicinesResident(data);
    }

    const onChangeTime = (value, index) => {
        let data = { ...medicines_resident };
        data.hours[index] = value;
        setMedicinesResident(data);
    }

    const clearAdd = () => {
        setSelectedadministration([])
        setMedicinesResident({
            id_resident: '',
            medication_name: '',
            weight: '',
            unit_measurement: '',
            administration: [],
            administration_str: '',
            number_hours: '0',
            hours: [],
            hours_str: '',
            status: '',
            create_date: '',
            update_date: '',
            user_create: '',
            user_update: ''
        })
    }

    const addMedicine = () => {
        let count = countId + 1;
        setCountId(count);
        let medicine = { ...medicines_resident };
        if(validFields()){
            medicine.administration = selectedadministration;
            medicine.administration_str = generateAdministration();
            medicine.hours_str = generateHours();
            medicine.id = resident.document+'-'+countId;
            setMedicines([...medicines, medicine]);
            clearAdd();
        }else{
            alertsApp.errorAlert('Existen campos obligatorios vacios');
        }
    }

    const generateHours = () => {
        let hours = '';
        medicines_resident.hours.forEach(hour => {
            hours += hour + ',';
        })
        return hours.slice(0, -1);
    }

    const generateAdministration = () => {
        let administration = '';
        let days = ['Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado', 'Domingo']
        let daysMin = {
            Lunes: 'Lun', Martes: 'Mar', Miércoles: 'Mié', Jueves: 'Jue',
            Viernes: 'Vie', Sábado: 'Sáb', Domingo: 'Dom'
        }
        let countDays = 0;
        let otherText = '';
        selectedadministration.forEach(item => {
            administration += daysMin[item] + ',';
            if (days.includes(item)) {
                countDays++;
            } else {
                otherText = item;
            }
        })
        let result = '';
        if (otherText !== '') {
            result = otherText;
        } else {
            if (countDays === days.length) {
                result = 'Todos los días';
            } else {
                result = administration.slice(0, -1);
            }
        }
        return result;
    }

    const validFields = () => {
        let result = true;
        if (!medicines_resident.medication_name || !medicines_resident.weight ||
            !medicines_resident.unit_measurement || !selectedadministration ||
            !medicines_resident.number_hours) {
            result = false;
        }
        return result;
    }

    const deleteMedicine = () => {
        let medicinesArray = [...medicines]
        let index = medicinesArray.indexOf(medicineSelect);
        medicinesArray.splice(index, 1);
        setMedicines(medicinesArray);
        if(origin === 'edit'){
            deleteMedicineFirestore(medicineSelect.id);
        }else{
            alertsApp.successAlert('Eliminado con exito');
        }
    }

    const deleteMedicineFirestore = (id) => {
        medicinesResidentsService.deleteRegister(id, () => {
            alertsApp.successAlert('Eliminado con exito');
        })
    } 

    useEffect(() => {
        setExitDocumentResident(false);
        if(resident.document){
            setExitDocumentResident(true);
        }
    }, [])

    return (
        <div>
            {!exitDocumentResident && 
                <div className="alert alert-warning alert-dismissible fade show" role="alert" style={{marginBottom: 30}}>
                    <strong>Alerta!</strong> No tiene un número de documento registrado en <strong>datos personales</strong>.
                </div>
            }
            <div className="row" style={{ marginTop: 15 }}>
                <div className="col-md-2">
                    <div className="form-group local-forms">
                        <label className="focus-label">Medicamento <span className="login-danger">*</span></label>
                        <input
                            type="text"
                            name="medicines_resident.medication_name"
                            className="form-control floating"
                            maxLength={10}
                            value={medicines_resident.medication_name}
                            onChange={onChange}
                        />
                    </div>
                </div>
                <div className="col-md-2">
                    <div className="form-group local-forms">
                        <label className="focus-label">Gramaje <span className="login-danger">*</span></label>
                        <input
                            type="text"
                            name="medicines_resident.weight"
                            className="form-control floating"
                            value={medicines_resident.weight}
                            onChange={onChange}
                        />
                    </div>
                </div>
                <div className="col-md-2">
                    <div className="form-group local-forms">
                        <label className="focus-label">U. medida <span className="login-danger">*</span></label>
                        <input
                            type="text"
                            name="medicines_resident.unit_measurement"
                            className="form-control floating"
                            value={medicines_resident.unit_measurement}
                            onChange={onChange}
                        />
                    </div>
                </div>

                <div className="col-md-2" style={{ fontSize: '0.8em' }}>
                    <div className="form-group local-forms">
                        <label className="focus-label">Administración</label>
                        <Space direction="vertical" style={{ width: '100%' }}>
                            <Select
                                {...sharedProps}
                                {...selectProps}
                                className="form-control floating"
                                maxTagPlaceholder={(omittedValues) => (
                                    <Tooltip title={omittedValues.map(({ label }) => label).join(', ')}>
                                        <span>Ver días</span>
                                    </Tooltip>
                                )}
                                style={{ padding: 5 }}
                            />
                        </Space>
                    </div>
                </div>
                <div className="col-md-2">
                    <div className="form-group local-forms">
                        <label className="focus-label">N° horas <span className="login-danger">*</span></label>
                        <input
                            type="text"
                            name="medicines_resident.number_hours"
                            className="form-control floating"
                            max={6}
                            value={medicines_resident.number_hours}
                            onChange={onChange}
                            onBlur={onBlur}
                        />
                    </div>
                </div>
                <div className="col-12 col-md-2">
                    <div className="doctor-submit">
                        <button
                            type="button"
                            className="btn btn-primary submit-list-form me-2"
                            onClick={addMedicine}
                            disabled={!exitDocumentResident}
                        >
                            Agregar
                        </button>
                    </div>
                </div>
            </div>
            <div className="row">
                {medicines_resident.hours.map((hour, indexH) => (
                    <div className="col-md-2" key={'H' + indexH}>
                        <TimePicker
                            defaultValue={dayjs(hour, format)}
                            format={format}
                            className="form-control datetimepicker"
                            onChange={(time, timeString) => {
                                onChangeTime(timeString, indexH);
                            }}
                        />
                    </div>
                ))}
            </div>
            <div>
                <div className="table-responsive">
                    <Table
                        className="table table-stripped table-hover datatable thead-light "
                        columns={column}
                        dataSource={medicines}
                        rowKey={(record) => record.id}
                    />
                </div>
            </div>
            <div id="delete_patient" className="modal fade delete-modal" role="dialog">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-body text-center">
                            <img src={imagesend} alt="#" width={50} height={46} />
                            <h3>Esta seguro de eliminar el registro ?</h3>
                            <div className="m-t-20">
                                {" "}
                                <Link to="#" className="btn btn-white me-2" data-bs-dismiss="modal">
                                    Cancelar
                                </Link>
                                <button type="button" 
                                    className="btn btn-danger" 
                                    data-bs-dismiss="modal" 
                                    onClick={deleteMedicine}
                                >Eliminar</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MedicinesResident;
