/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, createContext, useContext } from "react";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

import Header from "../Header";
import Sidebar from "../Sidebar";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { Link, useNavigate } from 'react-router-dom';

import { functions } from '../../utils/functions';
import { alertsApp } from '../../utils/alertsApp';
import { userService } from "../../services/user.service";
import { residentsService } from "../../services/resident.service";
import { rolesService } from "../../services/roles.service";
import { medicinesResidentsService } from "../../services/medicines_resident.service";

import PersonalInformation from "./PersonalInformation";
import MedicinesResident from "./MedicinesResident";
import Attachments from "./Attachments";

import firebaseConfig from '../../utils/Firebase';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/storage';
const secondaryApp = firebase.initializeApp(firebaseConfig, 'Secondary');

const ResidentContext = createContext(null);

const EditPatients = () => {
  const navigate = useNavigate();

  const [key, setKey] = useState('home');
  const [user, setUser] = useState({
    document: '',
    name: '',
    lastname: '',
    email: '',
    phone: '',
    phone1: '',
    phone2: '',
    status: '',
    create_date: '',
    update_date: ''
  });
  const [resident, setResident] = useState({
    document: '',
    name: '',
    lastname: '',
    blood_type: '',
    gender: '',
    security_type: '',
    country: '',
    birthday: '',
    room_number: '0',
    bed_number: '0',
    monthly_pension: '0',
    payday: '0',
    id_representative: '',
    representative: '',
    method_pay: '',
    attachments: [],
    status: '',
    create_date: '',
    update_date: '',
    user_create: '',
    user_update: ''
  });
  const [medicines, setMedicines] = useState([]);

  const [isBlockSave, setIsBlockSave] = useState(false);
  const [rolRepresentative, setRolRepresentative] = useState('');

  const onSelectTab = (key) => {
    setKey(key)
  }

  const cancelSave = () => {
    setIsBlockSave(false);
    setUser({
      document: '',
      name: '',
      lastname: '',
      email: '',
      phone: '',
      phone1: '',
      phone2: '',
      status: '',
      create_date: '',
      update_date: ''
    })
    setResident({
      document: '',
      name: '',
      lastname: '',
      blood_type: '',
      gender: '',
      security_type: '',
      country: '',
      birthday: '',
      room_number: '0',
      bed_number: '0',
      monthly_pension: '0',
      payday: '0',
      id_representative: '',
      representative: '',
      method_pay: '',
      attachments: [],
      status: '',
      create_date: '',
      update_date: '',
      user_create: '',
      user_update: ''
    })
    setMedicines([]);
    navigate('/residents');
  }

  const save = () => {
    setIsBlockSave(true);
    if (validFields()) {
      updateUser(user);
    } else {
      setIsBlockSave(false);
      alertsApp.errorAlert('Existen campos obligatorios vacios');
    }
  }

  const validFields = () => {
    let result = true;
    if (!resident.document || !resident.name || !resident.lastname || !resident.blood_type || !resident.gender ||
      !resident.security_type || !resident.country || !resident.birthday || !resident.room_number ||
      !resident.bed_number || !resident.monthly_pension || !resident.payday || !resident.method_pay) {
      result = false;
    }

    if (!user.name || !user.lastname || !user.document || !user.phone || !user.email) {
      result = false;
    }
    return result;
  }

  const updateUser = (data) => {
    let dataUpdate = {
      phone: data.phone,
      phone1: data.phone1,
      phone2: data.phone2,
      update_date: Date.now(),
      user_update: firebase.auth().currentUser.uid
    }
    userService.updateRegister(resident.id_representative, dataUpdate, () => {
      updateResident();
    });
  }

  const updateResident = () => {
    resident.status = '1';
    resident.update_date = Date.now();
    resident.user_update = firebase.auth().currentUser.uid;
    delete resident.user;
    let attachments = [...resident.attachments]
    delete resident.attachments;
    residentsService.updateRegister(resident.id, resident, () => {
      saveMedicines(resident.id, attachments);
    });
  }

  const saveMedicines = (idResident, attachments) => {
    let data = [...medicines];
    data.forEach((item) => {
      item.status = '1';
      item.id_resident = idResident;
      item.create_date = Date.now();
      item.update_date = Date.now();
      item.user_create = firebase.auth().currentUser.uid;
      item.user_update = firebase.auth().currentUser.uid;
    })
    medicinesResidentsService.setMedicines(data, () => {
      if(attachments.length > 0){
        saveImage(attachments, idResident, 0);
      }else{
        setIsBlockSave(false);
        alertsApp.successAlert('Registrado con exito');
        cancelSave();
      }
    });
  }

  const saveImage = (attachments, id_resident, index) => {
    let lastIndexAttach = attachments.length - 1;
    if(attachments[index].name == 'nuevo'){
      let arrayType = attachments[index].type.split('/');
      let indexImage = attachSequential(attachments);
      let nameImage = 'attachments/' + id_resident + '-' + indexImage + '.' + arrayType[1];
      firebase.storage().ref(nameImage).put(attachments[index].file)
        .then((snapshot) => {
          firebase.storage().ref(nameImage).getDownloadURL()
            .then((url) => {
              if (url) {
                attachments[index].name = nameImage;
                attachments[index].file = '';
                attachments[index].path = url;
                attachments[index].update = Date.now();
  
                if (index == lastIndexAttach) {
                  updateAttachmentsResident(id_resident, attachments)
                } else {
                  let nextIndex = index + 1
                  saveImage(attachments, id_resident, nextIndex);
                }
              }
            })
        });
    }else{
      if (index == lastIndexAttach) {
        updateAttachmentsResident(id_resident, attachments)
      } else {
        let nextIndex = index + 1
        saveImage(attachments, id_resident, nextIndex);
      }
    }
  }

  const attachSequential = (attachments) => {
    let sequential = []
    let result = 0
    if (attachments.length > 0) {
      attachments.forEach(item => {
        if (item.name !== 'nuevo') {
          let arrayName = item.name.split('-')
          let lastIndex = arrayName.length - 1
          sequential.push(parseInt(arrayName[lastIndex]))
        }else {
          sequential.push(0)
        }
      })
      result = Math.max(...sequential) + 1
    }

    return result
  }

  const updateAttachmentsResident = (id, attachments) => {
    let data = { attachments: attachments }
    residentsService.updateRegister(id, data, () => {
      setIsBlockSave(false);
      alertsApp.successAlert('Registrado con exito');
      cancelSave();
    })
  }

  const getMedicinesResident = (resident) => {
    medicinesResidentsService.getMedicinesResident(resident.id).then(snapshot => {
      let medicines = [];
      snapshot.forEach(doc => {
        let item = doc.data();
        item.id = doc.id;
        medicines.push(item);
      });
      setMedicines(medicines);
    })
  }

  useEffect(() => {
    setKey('personal_info');
    let resident = residentsService.getResident();
    if (resident) {
      setRolRepresentative(resident.user.rol);
      setUser(resident.user);
      
      setResident(resident);
      getMedicinesResident(resident);
    }
  }, [])

  return (
    <ResidentContext.Provider
      value={{
        user,
        setUser,
        resident,
        setResident,
        medicines,
        setMedicines
      }}
    >
      <Header />
      <Sidebar
        id="menu-item2"
        id1="menu-items2"
        activeClassName="edit-resident"
      />
      <>
        <div className="page-wrapper">
          <div className="content">
            {/* Page Header */}
            <div className="page-header">
              <div className="row">
                <div className="col-sm-12">
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="#">Residentes </Link>
                    </li>
                    <li className="breadcrumb-item">
                      <i className="feather-chevron-right">
                        <FeatherIcon icon="chevron-right" />
                      </i>
                    </li>
                    <li className="breadcrumb-item active">Editar residente</li>
                  </ul>
                </div>
              </div>
            </div>
            {/* /Page Header */}
            <div className="row">
              <div className="col-sm-12">
                <div className="card-box">
                  <h3 className="card-title">Editar residente</h3>
                  <Tabs
                    id="controlled-tab-example"
                    activeKey={key}
                    onSelect={(k) => onSelectTab(k)}
                    className="mb-3"
                  >
                    <Tab eventKey="personal_info" title="Datos personales">
                      {key === 'personal_info' &&
                        <PersonalInformation origin="edit" context={ResidentContext} />
                      }
                    </Tab>
                    <Tab eventKey="attachments" title="Adjuntos">
                      {key === 'attachments' &&
                          <Attachments origin="edit" context={ResidentContext} />
                        }
                    </Tab>
                    <Tab eventKey="medicines" title="Medicamentos">
                      {key === 'medicines' &&
                        <MedicinesResident origin="edit" context={ResidentContext} />
                      }
                    </Tab>
                  </Tabs>
                  <div className="row" style={{ justifyContent: 'end' }}>
                    <div className="col-12 col-md-3 col-xl-3">
                      <div className="doctor-submit">
                        <button
                          type="button"
                          className="btn btn-light submit-list-form me-2"
                          onClick={cancelSave}
                        >
                          Cancelar
                        </button>
                      </div>
                    </div>
                    <div className="col-12 col-md-3 col-xl-3">
                      <div className="doctor-submit">
                        <button
                          type="button"
                          className="btn btn-primary submit-list-form me-2"
                          onClick={save}
                          disabled={isBlockSave}
                        >
                          {isBlockSave &&
                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                          }
                          {isBlockSave ? ' Procesando...' : 'Registrar'}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </ResidentContext.Provider>
  );
};

export default EditPatients;
